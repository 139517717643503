<template>
  <div class="detail-container">
    <div class="operator-bar">
      <el-button type="primary" @click="handleUpdate">编辑</el-button>
    </div>
    <div class="detail-info" v-loading="loading">
      <el-row>
        <el-col :span="4">banner名称：</el-col>
        <el-col :span="20">{{info.activityName}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">封面图：</el-col>
        <el-col :span="20"><img :src="info.thumb" class="thumb" :width="thumbWidth"></el-col>
      </el-row>
      <el-row>
        <el-col :span="4">活动状态：</el-col>
        <el-col :span="20">
          <el-radio v-model="info.activityStatus" label="OPEN" border :disabled="info.activityStatus == 'HIDDEN'">开启</el-radio>
          <el-radio v-model="info.activityStatus" label="HIDDEN" border :disabled="info.activityStatus == 'OPEN'">隐藏</el-radio>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">活动链接：</el-col>
        <el-col :span="20">{{info.activityLink}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">地点：</el-col>
        <el-col :span="20">{{info.address}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">活动时间：</el-col>
        <el-col :span="20">{{info.activityStartTime | formatTime('{y}-{m}-{d} {h}:{i}') }} - {{info.activityEndTime | formatTime('{y}-{m}-{d} {h}:{i}') }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">报名截止时间：</el-col>
        <el-col :span="20">{{info.applyEndTime | formatTime('{y}-{m}-{d} {h}:{i}') }}</el-col>
      </el-row>
      <el-row>
        <el-col :span="4">活动简介：</el-col>
        <el-col :span="20">{{info.activityIntro}}</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {getActivityDetail} from '@/api/activity'
import { parseTime } from '@/utils/tool.js'

export default {
  data() {
    return {
      activityId: this.$route.query.activityId,
      info: {},
      loading: true,
      thumbWidth: this.$route.meta.columnId == 17 ? 557 :354
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      getActivityDetail({activityId:this.activityId}).then(res => {
        this.info = res.data
        this.loading = false
      })
    },
    handleUpdate() {
      const path = this.$route.matched.slice(this.$route.matched.length-2, this.$route.matched.length-1)[0].path
      this.$router.push(path+'/edit?activityId='+this.activityId)
    }
  },
  filters: {
    formatTime(time, format) {
      return parseTime(time, format)
    }
  }
}
</script>
